.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rdrMonthAndYearPickers select{
  box-shadow: 0 0 0;
}
.nft_market .inner-info h3{
  display: flex;
}
.itemname{
  width: 83%;
}
.itemcolors {
  width: 17%;
  line-height: 1;
  font-size: 12px;
  white-space: break-spaces;
  margin-top: 19px;
}
.nft_market .main-info .col-6 h3 .itemcolors{
  margin-top: 2px;
  font-size: 10px;
}
.itemcolor{
  font-weight: 400;
}
.rnftavailable {
  background: #FF80B5;
  border-radius: 25px;
  position: absolute;
  right: -10px;
  top: -10px;
  color: #fff;
  font-size: 10px;
  line-height: 1;
  width: 25px;
  height: 25px;
  padding: 7px 0 0;
  font-weight: bold;
}
.colslide {
  text-align: center;
  width: 160px !important;
  margin: 0 10px;
}
.colslide img{
  text-align: center;
}
.colslidew{
  text-align: center;
  margin: 10px;    
}
.colslidew img{
    text-align: center;
    max-width: 100%;
}
.colslidet{
    margin: 10px;
}
.collection{
  margin-left: -82px;
  margin-right: -82px;
  margin-bottom: 30px;
}
.collection h1{
  margin-bottom: 30px;
}

.redemptions{
  margin-top: 30px;
}
.redemption {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 10px 0;
  margin-bottom: 10px;
}
.redemptionname{
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 94.3%;  
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: left;  
}
.redemptionname a{
    color: rgb(33, 37, 41);
    text-decoration: none;
}
.redemptiondetail{
  display: flex;
}
.redemptionleft{
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  width: 250px;
}
.redemptionleft span{
  display: block;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 150%;  
}
.redemptionright{
  width: calc(100% - 250px);
}
.redemptionright p{
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 1.3;
  text-align: right;
  margin: 0;
}
.redemptionright.reddem p{
  color: rgba(0, 0, 0, 0.3);
}
.redemptionright a{
    color: var(--bs-body-color);
    text-decoration: none;
}
.nonslidercontrainer {
  margin-left: -52px;
  margin-right: -52px;
}
.nonslider{
    display: flex;
}
.nonslider .colslide{
    
}
.colslide img{
    max-width: 100%;
}
.dropdown-menu button{
    color: #111;
}
.orderbox-content .form-control{
    margin-bottom: 15px !important;
}
.snft{
    width: 100%;
    display: flex;
}
.snft p{
    width: 50%;
}
.snft p:last-child{
    text-align: right;
}