@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800&display=swap");

body {
  font-family: "Mulish", sans-serif;
  color: #000000;
  font-size: 20px;
  line-height: 26px;
  font-weight: 200;
  width: 100%;
  margin: 0 auto;
}

h1 {
  color: #000000;
  font-family: "Mulish", sans-serif;
  font-weight: 200;
  text-decoration: none;
  font-size: 48px;
  line-height: 1;
}

a {
  color: #00a359;
  text-decoration: none;
}

em {
  color: #c9232b;
  font-style: normal;
}

i {
  color: #00a359;
  font-size: 18px;
}

p {
  margin: 0;
}
.dropdown-menu.show {
  min-width: 181px;
}
.form-group {
  position: relative;
}

.btn.btn-primary,
.form-control,
.btn.btn-secondary {
  border: 1px solid #000000;
  border-radius: 10px;
  max-width: 368px;
  padding: 0 30px;
  line-height: 63px;
  background: transparent !important;
  box-shadow: none !important;
  text-shadow: none !important;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  color: #000000;
  text-align: left;
  position: relative;
  margin-bottom: 60px !important;
}

.form-control {
  border: 1px solid #000000 !important;
  border-radius: 10px;
  width: 340px !important;
  padding: 0 30px;
  height: 60px !important;
  /* line-height: 63px; */
  background: transparent !important;
  box-shadow: none !important;
  text-shadow: none !important;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  color: #000000;
  text-align: left;
}

@media (max-width: 430px) {
  .form-control {
    width: 300px !important;
  }
}

@media (max-width: 375px) {
  .form-control {
    width: 270px !important;
  }
}

@media (max-width: 350px) {
  .form-control {
    width: 250px !important;
  }
}

.btn.btn-primary:after,
.form-group img,
.btn.btn-secondary:after {
  position: absolute;
  right: 20px;
  top: 20px;
  content: "";
  display: block;
  width: 29px;
  height: 24px;
  /* background: url(../images/store.png) no-repeat right center transparent; */
}

.btn.btn-primary.store:after {
  /* background-image: url(../images/market.png); */
  width: 24px;
  height: 26px;
}

.btn.btn-secondary {
  background: #404040 !important;
  border-radius: 40px;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: #ffffff;
  padding: 5px 48px 5px 12px;
  margin-bottom: 0px !important;
}

.btn.btn-secondary:after {
  /* background-image: url(../images/bag.png); */
  width: 24px;
  height: 22px;
  top: 12px;
  right: 15px;
  background-repeat: no-repeat;
  border: none;
}

.home {
  /* background: url(../images/Main_BG.jpg) no-repeat center center transparent; */
  padding: 130px 0 100px;
}

.home h1 {
  padding-bottom: 80px;
}

.home h1 span {
  font-size: 30px;
}

.home p {
  max-width: 250px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.nft_market .d-flex {
  padding: 20px 0px 20px;
}

.nft_market {
  padding: 30px;
  background-image: url(../src/assets/Main_BG.jpg) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  min-height: 100vh !important;
  text-align: center !important;
  width: 428px !important;
  overflow: hidden;
}

.nft_market_ {
  padding-top: 50px;
}

.nft_market h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

.nft_market h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}

.inner-info {
  text-align: left !important;
}

.nft_market .inner-info {
  padding: 0;
  background: #fff;
}

.nft_market .main-info {
  margin-bottom: 30px;
}

.nft_market .inner-info h3 {
  font-weight: 200;
  font-size: 20px;
  line-height: 78px;
  color: #000000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin: 0;
  padding: 0 30px;
}

.nft_market .inner-info .d-flex {
  padding: 16px 30px;
}

.nft_market .inner-info .d-flex a,
.nft_market .inner-info .d-flex span {
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #000000;
}

.nft_market .inner-info .d-flex span {
  text-align: right;
}

.nft_market .main-info .col-6 {
  /* margin-right: 4.08%; */
  width: calc(50% - 4.08%);
}

/* .nft_market .main-info .col-6:last-child {
  margin-left: 4.08%;
  width: calc(50% - 4.08%);
  margin-right: 0;
} */

.nft_market .main-info .col-6 h3 {
  line-height: 35px;
  font-size: 10px;
  padding: 0 15px;
}

.nft_market .col-6 .inner-info .d-flex {
  padding: 8px 15px;
}

.nft_market .col-6 .inner-info .d-flex a,
.nft_market .col-6 .inner-info .d-flex span {
  font-size: 8px;
}

.nft_market .col-6 .inner-info .d-flex span img {
  width: 7px;
}

.main-info select {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 68.23%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  /* background-image: url(../images/dropdown.png); */
  background-repeat: no-repeat;
  background-position-y: 25px;
  background-position-x: 330px;
  border-radius: 10px;
  border: none;
  font-weight: 300;
  padding: 15px 20px;
  position: relative;
}

/* .main-info select:after {
	position: absolute;
	width: 18px;
	height: 18px;
	content: '';
	display: block;
	background-image: url(../images/dropdown.png);
	top: 15px;
	left: 20px;

} */
.redemption_store_item_inner {
  background: linear-gradient(
    128.3deg,
    rgba(255, 255, 255, 0) 35.32%,
    rgba(255, 255, 255, 0.9) 75.19%
  );
  border-radius: 5px;
  height: 204px;
}
.redemption_store_item {
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  position: relative;
  height: 204px;
}
.redemption_store_item_1 {
  background: url(../src/assets/store_item_1.png);
}
.redemption_store_item_2 {
  background: url(../src/assets/store_item_1.png);
}
.redemption_store_item_3 {
  background: url(../src/assets/store_item_1.png);
}

.redemption_store_text {
  position: absolute;
  right: 15px;
  bottom: 15px;
  text-align: right;
  font-weight: 20px;
  color: #000;
  display: grid;
}
.redemption_store_text span:first-child {
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
}
.redemption_store_text span:last-child {
  font-size: 30px;
  line-height: 1;
  font-weight: 300;
}

.main-info select:focus {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.redeemBtn {
  background: linear-gradient(
    -180deg,
    #8df5f5 0%,
    rgba(8, 102, 102, 0.54) 47.4%
  ) !important;

  border: 2px solid #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 100% !important;
  color: #fff !important;
  padding: 15px !important;
  display: block !important;
  text-align: center !important;
  cursor: pointer !important;
  text-decoration: none !important;
}
.redeemBtn:hover,
.redeemBtn:active,
.redeemBtn:visited {
  color: #fff;
}

.confirmpopup2 {
  display: none !important;
}

.market-button {
  width: 100%;
  height: 60px;
  border: 1px solid black !important;
  border-radius: 10px !important;
  margin-bottom: 40px !important;
  text-align: left !important;
  text-decoration: none !important;
}

.confirmpopup {
  position: fixed !important;
  width: 370px !important;
  bottom: -2% !important;

  background-color: #fff !important;
  height: auto !important;
  z-index: 2;
  text-align: left !important;
  border-radius: 0px -8px 20px rgba(0, 0, 0, 0.5);
  border-radius: 25px 25px 0 0;
  transition-property: all;
  transition-duration: 0.8s;
  padding: 20px !important;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.confirmpopup2 {
  display: none !important;
}

.sbn {
  display: none !important;
}

.sbn2 {
  display: block !important;
}

.orderbox {
  position: fixed !important;
  background-color: #fff !important;
  border-radius: 20px !important;
  width: 370px !important;
  bottom: -2% !important;
  height: auto !important;
  text-align: left !important;
  padding: 20px !important;
  transition: 0.5s !important;
  z-index: 2 !important;
}

.market-image {
  width: 100% !important;
  height: 170px !important;
  overflow: hidden !important;
  background-color: #fff !important;
}

.market-image2 {
  width: 100% !important;
  overflow: hidden !important;
}

.hi {
  margin-bottom: 0px !important;
}

.img-fluidss {
  width: 100% !important;
  height: auto !important;
}
.img-fluidss2 {
  width: 100% !important;
  height: auto !important;
}

.text-decoration {
  text-decoration: none !important;
}

.orderbox2 {
  display: none !important;
}
.orderbox3 {
  overflow-y: scroll !important;
  height: 500px !important;
}
.orderbox3 .innerscroll{
  width: 96%;
}
.confirmpopup .btn-close,
.orderbox .btn-close {
  display: none;
  padding: 20px;
}
.showconfirmbox .confirmpopup,
.showorderbox .orderbox {
  max-height: 423px;
}

.confirmpopupconnect2 {
  display: none !important;
}
.showconfirmboxconnected .confirmpopupconnect {
  max-height: 423px;
}
.showorderbox .orderbox.obn {
  max-height: 700px;
}
.showconfirmbox .confirmpopup .btn-close,
.showconfirmboxconnected .confirmpopupconnect .btn-close,
.showorderbox .orderbox .btn-close {
  display: block;
}

.confirmpopupconnect {
  position: fixed !important;
  background-color: #fff !important;
  border-radius: 20px !important;
  width: 370px !important;
  bottom: -2% !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  height: auto !important;
  text-align: right !important;
  padding: 20px !important;
  z-index: 2 !important;
  transition: 0.5s ease !important;
}

@media (max-width: 495px) {
  .nft_market {
    width: 100% !important;
  }
  .confirmpopupconnect {
    width: 85% !important;
  }
  .orderbox {
    width: 85% !important;
  }

  .confirmpopup {
    width: 85% !important;
  }
}

.showconfirmbox .overloay,
.showconfirmboxconnected .overloay,
.showorderbox .overloay {
  content: "" !important;
  background: rgba(0, 0, 0, 0.5) !important;
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 1 !important;
}
.confirmpopup-bottom {
  border-top: 1px solid #eee;
 /* padding: 20px 30px; */
}

.overloay1 {
  content: "" !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 1 !important;
}

.overloay2 {
  display: none !important;
}

.market-button {
  border: 1px solid #000000;
  border-radius: 10px;
  max-width: 368px;
  padding: 0 30px;
  line-height: 120px;
  background: transparent !important;
  box-shadow: none !important;
  text-shadow: none !important;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  color: #000000 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.market-button2 {
  border: 1px solid #000000;
  border-radius: 10px;
  max-width: 368px;
  padding: 0 30px;

  background: transparent !important;
  box-shadow: none !important;
  text-shadow: none !important;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  color: #000000 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.confirmpopup-bottom {
  border-top: 1px solid #eee;
  padding: 20px 30px;
}
.modalBtn {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 68.23%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  font-weight: 300;
  font-size: 18px;
  padding: 15px 20px;
  width: 100%;
  text-align: left;
  position: relative;
  display: block;
  color: #000;
  cursor: pointer;
  text-decoration: none;
}
.modalBtn:hover,
.modalBtn:active,
.modalBtn:visited {
  color: #000;
}
.modalBtn:after {
  position: absolute;
  right: 20px;
  top: 20px;
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  /* background: url(../images/arrow-right.png) no-repeat right center transparent; */
}

.grow_p {
  max-width: 290px;
  margin: 0 auto;
  padding-bottom: 50px;
}

/* .border-bm {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
} */

.p-span {
  font-size: 28px;
}

.modal-dialog {
  max-width: 400px;
}

.modal-header {
  border-bottom: 0;
}

.showselectorder {
  cursor: pointer !important;
  text-decoration: none !important;
}

.modal-body {
  padding: 0;
}

div.group {
  display: flex;
  padding: 0 10px 20px;
}

.minusmonth,
.addmonth {
  color: green !important;
}

.p-30 {
  padding: 0 30px;
}

.group {
  list-style-type: none;
  padding-left: 0;
}
ul.group {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
ul.group li {
  text-align: center;
  font-size: 16px;
}
ul.group li.red {
  background: #36b2b2;
  color: #fff;
  border-radius: 25px;
}
ul.group li.red + ul.group li.red {
  border-radius: 0 25px 0 25px;
}
.left {
  float: left;
}

.right {
  float: right;
}

.monthname {
  width: 18em;
}

.pointer {
  cursor: pointer;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

.calendar.dynamic {
  display: block;
}
.calendar {
  display: inline-grid;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 10px 20px -20px rgba(0, 0, 0, 0.3);
  max-width: 100%;
}
.calendar .month {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 300;
}
.calendar .month .year {
  font-weight: 600;
  margin-left: 10px;
}
.calendar .month .nav {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #0a3d62;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  transition-duration: 0.2s;
  position: relative;
}
.calendar .month .nav:hover {
  background: #eee;
}
.calendar .days {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(7, 1fr);
  color: #36b2b2;
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 14px;
}
.calendar .days span {
  width: 40px;
  justify-self: center;
  align-self: center;
  text-align: center;
}
.calendar .dates {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.calendar .dates button {
  cursor: pointer;
  outline: 0;
  border: 0;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  justify-self: center;
  align-self: center;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin: 2px;
  transition-duration: 0.2s;
  font-weight: 300;
}
.calendar .dates button.today {
  background: #36b2b2;
  color: #fff;
}
.calendar .dates button:first-child {
  grid-column: 3;
}
.calendar .dates button:hover {
  background: #eee;
}
.calendar .dates button:focus {
  background: #0a3d62;
  color: #fff;
  font-weight: 300;
}

.center {
  text-align: center;
}
.modal-content {
  border-radius: 1rem;
}
.modelitemtext {
  text-align: left;
  padding-left: 60px;
  color: #000;
}
.modelitemtext p {
  font-size: 20px;
}
.metamask {
  /* background: url(../images/metamask.png) no-repeat left center; */
  padding: 14px 60px;
  text-align: center;
}

.metamask2 {
  padding-top: 30px;
  padding-left: 40px;
  font-size: 20px;
  font-weight: lighter;
}

.confirmpopup-content2 {
  display: flex;
  align-items: center;
}

@media (max-width: 425px) {
  .metamask {
    padding: 0 !important;
  }
}

@media screen and (max-width: 375px) {
  .btn.btn-primary,
  .form-control {
    padding: 0 15px;
  }

  .btn.btn-primary:after,
  .form-group img {
    right: 15px;
  }
}

.wallet_btn {
  cursor: pointer !important;
}

.redemption-item {
  width: 100%;
  height: 114px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  margin-bottom: 25px !important;
  border-radius: 5px;
  cursor: pointer;
}

.redemption-image {
  width: 100% !important;
  height: 100% !important;
  border-radius: 5px;
}

.redemption-text {
  height: 100%;
  width: 100%;
  background: linear-gradient(
    128.3deg,
    rgba(255, 255, 255, 0) 35.32%,
    rgba(255, 255, 255, 0.9) 75.19%
  );
  position: absolute;
  border-radius: 5px;
  bottom: 0;
  right: 0;
}

.custom-snackbar {
  width: 250px;
  height: 50px;
  text-align: center;
  background-color: #d84646 !important;
  position: fixed;
  left: 0;
  bottom: 0;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 5px;
  color: #fff;
  margin: 20px;
  font-weight: 600;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.modal-main {
  width: 400px !important;
}
span[role="columnheader"]{
  color: #219653;
}
.MuiPickersArrowSwitcher-root svg{
  fill: #219653;
}
.MuiPickersDay-root.Mui-selected{
  background-color: #36B2B2 !important;
}
.timepickerc{
  margin-top: 7px;
}
.giftbtn{
  background:url('assets/gift.png') no-repeat 90% center;
  border: 1px solid #000000;
  border-radius: 10px;
  width: 100%;
  padding: 22px 25px 21px;
  display: block;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 1;
  color: #000000;
  text-decoration: none;  
  text-align: left;
}
.giftbtn:hover{
  color: #000000;
  cursor: pointer;
}
.slotbox{
    position: relative;
}
.slotpickerday{
    background: #fff;
    padding: 15px 30px;
    width: 100%;
    margin-bottom: 5px;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
}
.slotbox input{
    opacity: 0;
    position: absolute;
}
input:checked ~ .slotpickerday{
    background: #00a359;
}
.slotpicker{
  max-width: 100%;
  background:url('assets/timepicker-bg.png');
  padding-top: 15px;
  padding-bottom: 15px;
  background-size: contain;
  background-position: center center;
  background-repeat:  no-repeat;
}
.slotpicker .tsp__control{
  border: none !important;
  background: transparent;
  box-shadow: 0 0 0 !important;
}
.slotpicker .tsp__single-value{
  color: #000000;
  font-size: 20px;
}
.slotpicker .tsp__value-container{
  order: 1;
  text-align: left;
}
.slotpicker .tsp__indicators{
  order: 0;
  padding-left: 20px;
}
.slotpicker .tsp__indicator-separator{
  display: none;
}
.forminput{
  margin: 0 auto 10px;
}
.forminput label{
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 94.3%;
  color: #000000;
  display: block;
}
.forminput input{
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 100%;
  color: #000000;
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 10px 15px;
  width: 100%;
}


.btn:first-child:hover, :not(.btn-check) + .btn:hover{
  background-color: #eee !important;
  color: #000 !important;
}

.thanks{
  background-image: url(assets/thanks.jpg) !important;
  background-size: cover;
  padding-bottom: 10vh;
}
.thanks-msg{
background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.5);
border-radius: 10px;


font-style: normal;
font-weight: 300;
font-size: 20px;
line-height: 1;
color: #000000;
margin-top: 40vh;
padding: 30px;

}
.shareit-text{


  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height:1;
  text-align: center;
  
  color: #000000;
  
  }
.shareit-text{
  margin-top: 30px;
  margin-bottom: 30px;
}
.shareit-icons {
  margin-bottom: 50px;
}
.shareit-icons svg{
  margin: 0 15px;
}
.dropdown-menu.open{
  display: block;
}
@media (max-width: 450px) {
  .modal-main {
    width: 350px !important;
  }

  .calendar {
    transform: scale(0.9) !important;
  }

  /* .main-info {
    transform: scale(0.9) !important;
  } */
}

@media (max-width: 375px) {
  .modal-main {
    width: 300px !important;
  }
}

@media (max-width: 499px) {
  .market-image2 {

    background-color: #fff !important;
  }

  .market-image {
    height: 150px !important;
    background-color: #fff !important;
  }
}

@media (max-width: 405px) {
  .calendar {
    background: none !important;
  }
  .market-image2 {

  }

  .market-image {
    height: 130px !important;
    background-color: #fff !important;
  }
}
